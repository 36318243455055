import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Form as AntForm,
  Table,
  Tooltip,
  Tag,
  Modal,
  Select,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";

import { Link } from "react-router-dom";

import { AuthContext } from "contexts";

function Assignment() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [studyGroups, setStudyGroups] = useState([]);
  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
      fetchEntities("/courses", setCourses);
      fetchEntities("/groups", setStudyGroups);
      // fetchEntities("/participants", setParticipants); // Assuming endpoint exists
  }, []);

    const fetchEntities = (endpoint, setState) => {
      instance
        .get(endpoint)
        .then((response) => {
          setState(
            response.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))
          );
        })
        .catch((error) => console.error(`Failed to fetch ${endpoint}`, error));
    };
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/assignments`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
  

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
     {
      title: "Код",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Хичээл",
      dataIndex: "courseId",
      key: "courseId",
      render: (text, row) => <span>{row.course?.name} </span>,
    },
    {
      title: "Study Group",
      dataIndex: "groupId",
      key: "groupId",
      render: (text, row) => <span>{row.study_group?.name} </span>,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      render: (text, row) => <span>{text} </span>,
    },

    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };
  const fields = [
    {
      label: "Нэр",
      name: "name",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Код",
      name: "code",
      // rules: [{ required: true, message: "Код оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хичээл",
      name: "courseId",
      type: "select",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: courses,
      },
    },
    {
      label: "Study group",
      name: "groupId",
      type: "select",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: studyGroups,
      },
    },
    {
      label: "Тайлбар",
      name: "description",
      type: "textarea",
      className: "col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Deadline",
      name: "deadline",
      type: "date",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    // {
    //   label: "Бүлгийн ID",
    //   name: "groupId",
    //   type: "number",
    //   className: "col-span-12 md:col-span-6 mb-0",
    //   inputProps: {
    //     className: "w-full",
    //   },
    // },
    // {
    //   label: "Төрөл",
    //   name: "type",
    //   className: "col-span-12 md:col-span-6 mb-0",
    //   inputProps: {
    //     className: "w-full",
    //   },
    // },
    {
      label: "Оноо",
      name: "point",
      type: "number",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: `/assignment`,
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: `/assignment`,
        data: values,
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5 ">
        <div></div>
      </div>
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={true}
          title={"Даалгавар"}
          handleAdd={handleAdd}
          handleRefresh={fetchData}
        />
      </div>
      <Modal
        title={editData ? "Даалгавар мэдээлэл засах" : "Шинэ Даалгавар"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          // isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Assignment;
