import React, { useContext, useEffect, useState } from "react";
import { instance } from "utils/axios";
import { AuthContext } from "contexts";
import PieChart from "./PieChart";
import ColumnChart from "./ColumnChart";

const AdmissionsDashboard = ({}) => {
  const authContext = useContext(AuthContext);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/admission/dashboard`,
    })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setLoading(false));
  };

  return (
    <div className="px-1  md:px-5">
      <div className="grid grid-cols-24 gap-2">
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Нийт тоо</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.Total ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Accept тоо</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.acceptCount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-purple-700 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Төлбөр төлсөн</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.feeCount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted"> Дүн</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.feeAmount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-orange-400 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Reject</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.RejectCount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-green-700 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Шийдвэр гараагүй</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.notDecision ?? 0)}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex gap-2 items-start h-full gap-5">
        <PieChart
          data={[
            {
              name: "Үлдэгдэл",
              amount: data.sumData?.balance,
            },
            {
              name: "Төлсөн",
              amount: data.sumData?.paidAmount,
            },
            {
              name: "Fin Aid",
              amount: data.sumData?.finAid,
            },
          ]}
        />
      </div> */}
      <div className="grid grid-cols-12 gap-5 mt-5">
        <ColumnChart
          data={data?.dayData}
          className="col-span-12"
          alias="Өргөдлийн тоо/Өдөр/"
          xField="tDay"
          yField="cnt"
          start={0.2}
        />
      </div>
    </div>
  );
};

export default AdmissionsDashboard;
