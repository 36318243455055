import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, DatePicker } from "antd";
import moment from "moment";
import { instance } from "utils/axios"; // Make sure this path is correct for your project structure

const { Option } = Select;

const ScheduleForm = ({ form,onFormSubmit, scheduleTypes }) => {
  // const [form] = Form.useForm();
  const [scheduleType, setScheduleType] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [studyGroups, setStudyGroups] = useState([]);
  // const [participants, setParticipants] = useState([]); 

  useEffect(() => {
    fetchEntities("/facilities", setFacilities);
    fetchTeachers("/teachers", setTeachers);
    fetchEntities("/lessons", setLessons);
    fetchEntities("/groups", setStudyGroups);
    // fetchEntities("/participants", setParticipants); // Assuming endpoint exists
  }, []);

  const fetchEntities = (endpoint, setState) => {
    instance
      .get(endpoint)
      .then((response) => {
        setState(
          response.data.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      })
      .catch((error) => console.error(`Failed to fetch ${endpoint}`, error));
  };
   const fetchTeachers = (endpoint, setState) => {
    instance
      .get(endpoint)
      .then((response) => {
        setState(
          response.data.map((item) => ({
            id: item.id,
            firstName: item.firstName,
          }))
        );
      })
      .catch((error) => console.error(`Failed to fetch ${endpoint}`, error));
  };

  const handleTypeChange = (value) => {
    setScheduleType(value);
    form.resetFields([
      "title",
      "lessonId",
      "facilityId",
      "teacherId",
      "assignmentId",
      "groupId",
      "particepent",
    ]); // Reset specific fields on type change if needed
  };

  const onFinish = (values) => {
    const payload = {
      ...values,
      // startTime: values.startTime(),
      type: scheduleType,
    };
    onFormSubmit(payload);
  };

  return (
    <Form
      className="grid grid-cols-12 gap-4"
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        startTime: moment(),
        endTime: moment().add(1, "hours"),
      }}
    >
      <Form.Item
        className="col-span-12 md:col-span-6"
        name="type"
        label="Schedule Type"
        rules={[{ required: true, message: "Please select a schedule type!" }]}
      >
        <Select
          placeholder="Select a schedule type"
          onChange={handleTypeChange}
        >
          {scheduleTypes.map((type) => (
            <Option key={type.value} value={type.value}>
              {type.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Conditional form items based on the selected schedule type */}
      {scheduleType === "lesson" && (
        <>
          <Form.Item
            className="col-span-12 md:col-span-6"
            name="lessonId"
            label="Lesson"
            rules={[{ required: true, message: "Please select a lesson!" }]}
          >
            <Select placeholder="Select a lesson">
              {lessons.map((lesson) => (
                <Option key={lesson.id} value={lesson.id}>
                  {lesson.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="col-span-12 md:col-span-6"
            name="teacherId"
            label="Teacher"
            rules={[{ required: true, message: "Please select a teacher!" }]}
          >
            <Select placeholder="Select a teacher">
              {teachers.map((teacher) => (
                <Option key={teacher.id} value={teacher.id}>
                  {teacher.firstName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}

      {scheduleType && ( // Assuming all types need facility selection
        <Form.Item
          className="col-span-12 md:col-span-6"
          name="facilityId"
          label="Facility"
          rules={[{ required: true, message: "Please select a facility!" }]}
        >
          <Select placeholder="Select a facility">
            {facilities.map((facility) => (
              <Option key={facility.id} value={facility.id}>
                {facility.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {scheduleType === "assignment" && (
        <Form.Item
          className="col-span-12 md:col-span-6"
          name="assignmentId"
          label="Assignment"
          rules={[{ required: true, message: "Please select an assignment!" }]}
        >
          <Select placeholder="Select an assignment">
            {/* Assuming assignments data needs to be fetched similarly */}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        className="col-span-12 md:col-span-6"
        name="groupId"
        label="Study Group"
        rules={[{ required: true, message: "Please select a study group!" }]}
      >
        <Select placeholder="Select a study group">
          {studyGroups.map((group) => (
            <Option key={group.id} value={group.id}>
              {group.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        className="col-span-12 md:col-span-6"
        name="startTime"
        label="Start Time"
        rules={[{ required: true }]}
      >
        <DatePicker
          className="w-full"
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          placeholder="Select date and time"
        />
      </Form.Item>

      <Form.Item
        className="col-span-12 md:col-span-6"
        name="attendanceType"
        label="Attendance Type"
      >
        <Select>
          <Option value="None">None</Option>
          <Option value="TeacherQr">Багшийн QR</Option>
          <Option value="ParticipantQr">Сурагчийн QR</Option>
        </Select>
      </Form.Item>

      {/* <Form.Item
        className="col-span-12 md:col-span-6"
        name="particepent"
        label="Participant"
      >
        <Select placeholder="Select a participant">
          {participants.map((participant) => (
            <Option key={participant.id} value={participant.id}>
              {participant.name}
            </Option>
          ))}
        </Select>
      </Form.Item> */}

      <Form.Item className="col-span-12" name="note" label="Note">
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

export default ScheduleForm;
