import {
  Dashboard,
  Employee,
  Organization,
  Payers,
  AttendanceDashboard,
  Attendance,
  AttendanceReport,
  Transaction,
  BankTransaction,
  AdmissionsDashboard,
  Contract,
  Quiz,
  DailyQuiz,
  QuizDetail,
  Course,
  Lesson,
  Schedule,
  Facility,
  Group,
  Assignment
} from "modules/admin";

export default [
  {
    name: "Төлбөр Хянах самбар",
    path: "/tuition/dashboard",
    element: <Dashboard />,
    role: ["Админ", "Удирдлага", "Менежер"],
  },
  {
    name: "Төлбөр Сурагчид",
    path: "/tuition/payers",
    element: <Payers />,
    role: ["Админ", "Удирдлага", "Менежер", "Элсэлтийн ажилтан"],
  },
  {
    name: "Төлбөр Тайлан",
    path: "/tuition/report",
    element: <Employee />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Bank Transaction",
    path: "/tuition/transactions",
    element: <BankTransaction />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Essay to Insipe",
    path: "/essay/transaction",
    element: <Transaction />,
    role: ["CEO", "Админ", "Менежер"],
  },
  {
    name: "Attendance",
    path: "/attendance/dashboard",
    element: <AttendanceDashboard />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Attendance",
    path: "/student/attendance",
    element: <Attendance />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Attendance report",
    path: "/attendance/report",
    element: <AttendanceReport />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Attendance report",
    path: "/attendance/report",
    element: <AttendanceReport />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Quiz",
    path: "/quiz",
    element: <Quiz />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Quiz",
    path: "/daily-quiz",
    element: <DailyQuiz />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Quiz",
    path: "quiz-detail",
    element: <QuizDetail />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Quiz",
    path: "quiz-detail/:id",
    element: <QuizDetail />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Addmission Dashboard",
    path: "/",
    element: <AdmissionsDashboard />,
    role: ["Админ", "Удирдлага", "Менежер", "Элсэлтийн ажилтан"],
  },
  {
    name: "Schedule",
    path: "/schedule",
    element: <Schedule />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Course",
    path: "/course",
    element: <Course />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Assignment",
    path: "/assignment",
    element: <Assignment />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Group",
    path: "/group",
    element: <Group />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Lesson",
    path: "/lesson",
    element: <Lesson />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Facility",
    path: "/facility",
    element: <Facility />,
    role: ["CEO", "Админ", "Менежер", "Багш"],
  },
  {
    name: "Addmission Dashboard",
    path: "/admissions/dashboard",
    element: <AdmissionsDashboard />,
    role: ["Админ", "Удирдлага", "Менежер", "Элсэлтийн ажилтан"],
  },
  // Хүний нөөц
  {
    name: "Ажилчид",
    path: "/employee",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <Employee />,
  },
  // Тохирго
  {
    name: "Компаний мэдээлэл",
    path: "/organization",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <Organization />,
  },
  {
    name: "Гэрээ ",
    path: "/contract/:code",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <Contract />,
  },
  {
    name: "Not Found",
    path: "*",
    role: ["CEO", "Админ", "Менежер", "Багш"],
    element: (
      <div className=" flex items-center justify-center text-xl font-bold italic">
        Not Found
      </div>
    ),
  },
];
