import React from "react";
import { Header, Footer, Sidebar } from "./components";

const Layout = ({ children }) => {
  return (
    <div id="layout">
      <Header></Header>
      <Sidebar></Sidebar>
      {/* <Footer></Footer> */}
      <main>{children}</main>
    </div>
  );
};

export default Layout;
