import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Modal,
  Tag,
  Form as AntForm,
  Drawer,
  Tooltip,
  Select,
  message,
} from "antd";
import {
  EditOutlined,
  SearchOutlined,
  ClearOutlined,
  EyeOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Form, MainTable, CompanySelect } from "components";
import { instance } from "utils/axios";
import fields from "./fields";
import moment from "moment";
import StudentDetail from "./StudentDetail";
import { AuthContext } from "contexts";

function Payers() {
  const authContext = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pstatus, setPstatus] = useState("Бүгд");
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [detailLoading, setDetailLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [form] = AntForm.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, messageHolder] = message.useMessage();
  const [searchLoading, setSearchLoading] = useState(false);
  const [filterForm] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/students`,
    })
      .then((res) => {
        setData(res.data);
        if (editData) {
          setEditData(res.data.find((item) => item.id === editData.id));
        }
      })
      .catch((err) => {})
      .then(() => setLoading(false));

    setLoading(true);
  };

  const handleMore = (row) => {
    setDetailLoading(true);
    setSelectedCustomer(row);
    setShowDetail(true);
    instance({
      method: "get",
      url: `/student/${row.id}`,
    })
      .then((res) => {
        setDetailData(res.data);
      })
      .catch((err) => {})
      .then(() => setDetailLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,

      fixed: "left",
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
      width: 70,
      fixed: "left",
      align: "center",
    },
    {
      title: "Сурагчийн овог нэр",
      dataIndex: "firstName",
      key: "firstName",
      fixed: "left",
      render: (text, row) => (
        <span>
          {row.lastName} {text}
        </span>
      ),
      width: 250,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 80,
    },
    {
      title: "Регистер",
      dataIndex: "regno",
      key: "regno",
      width: 115,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Утас",
      dataIndex: "mobile",
      key: "mobile",
      width: 100,
    },

    // {
    //   title: "Асран хамгаалагч",
    //   dataIndex: "payerName",
    //   key: "payerName",

    //   width: 200,
    // },

    // {
    //   title: "Асран хамгаалагчийн утас",
    //   dataIndex: "payerPhone",
    //   key: "payerPhone",

    //   width: 100,
    // },
    // {
    //   title: "Асран хамгаалагчийн email",
    //   dataIndex: "payerMail",
    //   key: "payerMail",
    //   width: 250,
    // },

    {
      title: "Анги",
      dataIndex: "class",
      key: "class",

      width: 70,
    },
    // {
    //   title: "Төлбөр",
    //   dataIndex: "tuitionAmount",
    //   key: "tuitionAmount",

    //   render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    //   width: 120,
    // },
    {
      title: "Fin Aid",
      dataIndex: "finAid",
      key: "finAid",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
      width: 120,
    },
    {
      title: "Гэрээт дүн",
      dataIndex: "payAmount",
      key: "payAmount",
      render: (text, row) => (
        <span style={{ color: "green" }}>
          {Intl.NumberFormat().format(text)} ₮
        </span>
      ),
      width: 120,
    },
    {
      title: "Бартер",
      dataIndex: "barterAmount",
      key: "barterAmount",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
      width: 100,
    },
    {
      title: "ELF coin",
      dataIndex: "elfCoin",
      key: "elfCoin",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} </span>,
      width: 100,
    },
    {
      title: "Төлсөн дүн",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
      width: 120,
    },
    {
      title: "Үлдэгдэл",
      dataIndex: "balance",
      key: "balance",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
      width: 100,
    },
    {
      title: "Сүүлд төлсөн огноо",
      dataIndex: "lastPayDate",
      key: "lastPayDate",
      // render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
      width: 120,
    },
    {
      title: "Төлбөр хоцролт",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text, row) => {
        {
          return <Tag color={text == "Хэвийн" ? "green" : "red"}>{text}</Tag>;
        }
      },
      width: 100,
    },
    {
      title: "Үйлдэл",
      dataIndex: "action",
      eclipses: true,
      key: "action",
      width: 250,
      render: (text, row) => (
        <div className="flex items-center gap-5">
          <Button size="small" onClick={() => handleEdit(row)}>
            <div className="flex items-center gap-2">
              <EditOutlined /> Засах
            </div>
          </Button>
          <Button size="small" onClick={() => handleMore(row)}>
            <div className="flex items-center gap-2">
              <EyeOutlined /> Дэлгэрэнгүй
            </div>
          </Button>
        </div>
      ),
    },
  ];

  const searchFields = [
    {
      label: "Нэр",
      name: "name",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Код",
      name: "code",
      className: "col-span-12 md:col-span-6 mb-0",
      type:"number",
      inputProps: {
        className: "w-full",
      },
    },
  ];

  const handleFilterSubmit = (values) => {
    setSearchLoading(true);
    instance({
      method: "get",
      url: `/customer?pageindex=0&pagesize=${20}&name=${
        values.name ? values.name : ""
      }&mobile=${values.mobile ? values.mobile : ""}`,
    })
      .then((res) => {
        setData(res.data.customers);
      })
      .catch((err) => {})
      .then(() => setSearchLoading(false));
  };

  const handleEdit = (row) => {
    if (row.status != 0) {
      modal.error({
        title: "Анхаарна уу !",
        content: " мэдээлэл өөрчлөх боломжгүй.",
      });
      return;
    }
    setEditData({
      isAdd: false,
      customerData: row.customer,
      bookingData: row,
    });
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col px-4 pb-4">
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow mb-5 w-1/2">
        <div className="text-xl leading-none mb-3">Хайлт</div>
        <Form
          form={filterForm}
          fields={searchFields}
          length={data.length}
          layout="vertical"
          onFinish={handleFilterSubmit}
          className="gap-5"
        >
          <div className="col-span-12 flex justify-end gap-2 mt-4">
            <Button
              className="flex items-center"
              size="small"
              type="default"
              htmlType="submit"
              onClick={() => filterForm.submit()}
              loading={searchLoading}
            >
              <SearchOutlined />
              Хайх
            </Button>
            <Button
              className="flex items-center"
              size="small"
              type="default"
              onClick={() => filterForm.resetFields()}
            >
              <ClearOutlined />
              Цэвэрлэх
            </Button>
          </div>
        </Form>
      </div>
      <MainTable
        dataTable={
          pstatus == "Бүгд"
            ? data
            : data.filter((x) => x.paymentStatus == pstatus)
        }
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        // pagination={true}
        scroll={{ y: "calc(100vh - 200px)" }}
        title={`Сурагчид`}
        // handleAdd={handleAdd}
      />
      {/* <Modal
        title={"Шинжилгээ"}
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields({
            doctors,
            technicians,
            customers,
            services,
            editData,
            removeFunc: (id) => handleDeleteService(id),
            form,
            serviceTypes,
          })}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>
      */}
      <Drawer
        open={showDetail}
        onClose={() => setShowDetail(false)}
        title={`${moment(selectedCustomer?.currentDateTime).format(
          "YYYY-MM-DD"
        )} ${moment(selectedCustomer?.currentDateTime).format("HH:mm")}`}
        width={1000}
        bodyStyle={{ padding: 10 }}
      >
        {detailLoading ? (
          <div className="flex justify-center items-center h-full">
            <LoadingOutlined className="text-[40px]" />
          </div>
        ) : (
          <StudentDetail
            data={detailData}
            rowData={selectedCustomer}
            refreshData={handleMore}
          />
        )}
      </Drawer>
      {contextHolder}
      {messageHolder}
    </div>
  );
}

export default Payers;
