import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { instance } from "utils/axios";
import { LoadingOutlined, PrinterOutlined } from "@ant-design/icons";
import "./index.css";
import moment from "moment";
import signature from 'assets/images/signatue.png'
import { useReactToPrint } from "react-to-print";
import {
  Badge,
} from "antd";
function Contract() {
  const [data, setData] = useState(null);
  const [tuition, setTuition] = useState(null);
  const [loading, setLoading] = useState(false);
 let { code } = useParams();
  const componentRef = useRef();
  useEffect(() => {
     getData();
  }, []);

  const getData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `contract/student/${code}`,
    })
      .then((res) => {
        setData(res.data);
        setTuition(res.data.student.tuitions[0]);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});

  return (
    <div className=" min-h-screen min-w-screen bg-gray-600 flex">
      {loading ? (
        <div className="flex-1 flex justify-center items-center">
          <LoadingOutlined className="text-[24px]" />
        </div>
      ) : tuition ? (
        <div
          className="w-[700px] mx-auto bg-white p-[20px] relative flex flex-col min-h-[297mm]"
          ref={componentRef}
        >
          <button
            onClick={handlePrint}
            className="noprint md:absolute top-[80px] md:-right-[113px] shadow-xl bg-white border rounded-md p-2 hover:bg-gray-200 transition-all cursor-pointer"
          >
            <PrinterOutlined className="text-[24px]" /> Хэвлэх
          </button>
          <div
            className={`noprint md:absolute top-[30px] md:-right-[113px] shadow-xl ${
              tuition?.isContract == 1 ? "bg-cyan-600" : "bg-red-500"
            }  rounded-md p-2  text-slate-100`}
          >
            {tuition?.isContract == 1 ? "Зурсан" : "Зураагүй"}
          </div>
          <div className="text-[12px] text-end mt-5">
            <span className="font-bold">Гэрээний хавсралт №1</span>
          </div>
          <div className="text-[12px] text-start mt-5">
            &nbsp;&nbsp; Томүжин Алтернатив Сургуулийн захиргааны хурлын
            шийдвэрээр 2024-2025 оны хичээлийн жил {data?.student?.grade}-р
            ангид суралцах {data?.student?.lastName} овогтой{" "}
            {data?.student?.firstName} (РД:
            {data?.student?.regno}) -ын сургалтын төлбөрийг дараах байдлаар
            батлав.
          </div>
          <table className="w-full border-collapse border border-slate-400 mt-5 mb-6 text-[12px]">
            <tbody>
              <tr>
                <th className="border border-slate-400 font-bold text-[12px]">
                  No:{" "}
                </th>
                <th className="border border-slate-400 text-center text-[12px]">
                  Сургалтын төлбөрийн мэдээлэл
                </th>
                <th className="border border-slate-400 font-bold">
                  Мөнгөн дүн
                </th>
                <th className="border border-slate-400 text-center">Хувиар</th>
              </tr>
              <tr>
                <td className="border border-slate-400 text-center">1</td>
                <td className="border border-slate-400 text-start pl-2">
                  Сургалтын төлбөр
                </td>
                <td className="border border-slate-400 font-bold text-end pr-2">
                  {tuition?.tuitionAmount.toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td className="border border-slate-400 text-center">100%</td>
              </tr>
              <tr>
                <td className="border border-slate-400 text-center">2</td>
                <td className="border border-slate-400 text-start pl-2">
                  Сургалтын төлбөрийн дэмжлэгийн хэмжээ
                </td>
                <td className="border border-slate-400 font-bold text-end pr-2">
                  {tuition?.finAid.toLocaleString("en-US", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td className="border border-slate-400 text-center">
                  {tuition?.finAidPercent}%
                </td>
              </tr>
              <tr>
                <td className="border border-slate-400 text-center">3</td>
                <td className="border border-slate-400 text-start pl-2">
                  Тѳлѳх сургалтын тѳлбѳр
                </td>
                <td className="border border-slate-400 font-bold text-end pr-2">
                  {(tuition?.tuitionAmount - tuition?.finAid).toLocaleString(
                    "en-US",
                    {
                      maximumFractionDigits: 0,
                    }
                  )}
                </td>
                <td className="border border-slate-400 text-center">
                  {100 - (tuition ? tuition?.finAidPercent : 0)}%
                </td>
              </tr>
            </tbody>
          </table>

          <div className="text-[12px] text-start mt-5">
            <span className="font-bold">Сургалтын төлбөрийн хуваарь</span>{" "}
          </div>
          <table className="w-full border-collapse border border-slate-400 mt-5 mb-6 text-[12px]">
            <tbody>
              <tr>
                <th className="border border-slate-400 font-bold text-[12px]">
                  No:{" "}
                </th>
                <th className="border border-slate-400 text-center text-[12px]">
                  Төлбөрийн нэр
                </th>
                <th className="border border-slate-400 font-bold">Төлөх дүн</th>
                <th className="border border-slate-400 font-bold">
                  Төлөх хэлбэр
                </th>
                <th className="border border-slate-400 font-bold">Төлөв</th>
                <th className="border border-slate-400 text-center">
                  Төлөх хугацаа
                </th>
                <th className="border border-slate-400 text-center">
                  Гарын үсэг
                </th>
              </tr>
              {tuition?.tuition_schedules?.map((schedule, index) => (
                <tr key={index}>
                  <td className="border border-slate-400 text-center">
                    {index + 1}
                  </td>
                  <td className="border border-slate-400 text-start pl-2">
                    {schedule.title}
                  </td>
                  <td className="border border-slate-400 font-bold text-end pr-2">
                    {schedule.amount.toLocaleString("en-US", {
                      maximumFractionDigits: 0,
                    })}
                  </td>
                  <td className="border border-slate-400  text-center pr-2">
                    {schedule.paymentType}
                  </td>
                  <td className="border border-slate-400  text-center pr-2">
                    {schedule.status == "PAID" ? "Төлсөн" : "Төлөөгүй"}
                  </td>
                  <td className="border border-slate-400 text-center">
                    {moment(schedule.payDate).format("YYYY-MM-DD")}
                  </td>
                  <td className="border border-slate-400 text-center">
                    {" "}
                    {tuition?.isContract ? (
                      <img
                        src={data?.contract?.signature}
                        alt="signature"
                        border="0"
                        className="ml-2  h-[30px]"
                      />
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="text-[12px] text-start mt-5">
            <span className="font-bold">
              ГЭРЭЭНИЙ ХАВСРАЛТЫГ ЗӨВШӨӨРЧ, БАЙГУУЛСАН:
            </span>{" "}
          </div>
          <div className="border border-slate-400 mt-8 mb-2 text-xs  w-1/2 p-2">
            <div className="flex flex-col">
              <span>Суралцагчийг төлөөлж: </span>
              <span>Суралцагчийн эцэг, эх, асран хамгаалагч болох</span>
              <span>
                {" "}
                {data?.contract?.lastName} овогтой {data?.contract?.firstName}
              </span>
              <div className="flex flex-row mt-2">
                {" "}
                <span>Гарын үсэг</span>{" "}
                {tuition?.isContract ? (
                  <img
                    src={data?.contract?.signature}
                    alt="signature"
                    border="0"
                    className="ml-2  h-[30px]"
                  />
                ) : (
                  <></>
                )}
              </div>
              <span>Холбоо барих утас: data?.contract?.mobile</span>
              <span>
                Огноо:{" "}
                {tuition.isContract
                  ? moment(data.contract.confirmDate).format("yyyy-MM-DD")
                  : ""}
              </span>
            </div>
          </div>

          <div className="mt-auto text-[12px] flex justify-between items-center">
            <div className="flex items-center">
              Сургуулийг төлөөлж:
              <img
                src={signature}
                alt="signature"
                border="0"
                className="ml-2  h-[50px]"
              />
            </div>
            <div className="flex items-center">
              Эцэг, эх, асран хамгаалагчийг төлөөлж:
              {tuition?.isContract ? (
                <img
                  src={data?.contract?.signature}
                  alt="signature"
                  border="0"
                  className="ml-2  h-[50px]"
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        // </Badge.Ribbon>
        <div>No data</div>
      )}
    </div>
  );
}

export default Contract;
