import React, { useEffect, useState, useContext } from "react";
import { Button, Form as AntForm, Table, Tooltip, Tag, Modal } from "antd";
import { SearchOutlined, ClearOutlined, EditOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";

import { Link } from "react-router-dom";

import { AuthContext } from "contexts";

function BankTransaction() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataCount, setDataCount] = useState([]);
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/bank/transactions/${currentPage}/20`,
    })
      .then((res) => {
        console.log(res.data);
        setDataCount(res.data?.count);
        setData(res.data?.rows);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    // instance({
    //   method: "get",
    //   url: `bank/transaction/sum`,
    // })
    //   .then((res) => {
    //     console.log(res.data);
    //     setSumData(res.data[0]);
    //   })
    //   .catch((err) => {})
    //   .then(() => setLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Огноо",
      dataIndex: "transactionDate",
      key: "transactionDate",
    },
    {
      title: "Сар",
      dataIndex: "tMonth",
      key: "tMonth",
    },
    {
      title: "Дүн",
      dataIndex: "amount",
      key: "amount",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },
    {
      title: "Гүйлгээний утга",
      dataIndex: "description",
      key: "description",
      // render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },
    {
      title: "Данс",
      dataIndex: "account",
      key: "account",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },
  ];
  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={{ total: dataCount }}
          title={"Гүйлгээ"}
        />
      </div>
    </div>
  );
}

export default BankTransaction;
