import React, { useEffect, useState, useContext } from "react";
import { Button, DatePicker, Input, Tag, Select } from "antd";
import { SearchOutlined, FileExcelOutlined } from "@ant-design/icons";
import { MainTable } from "components";
import { instance } from "utils/axios";
import moment from "moment";
import { AuthContext } from "contexts";
import * as XLSX from "xlsx";

const { Option } = Select;

function DailyQuiz() {
  const { action } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment());
  const [searchText, setSearchText] = useState("");
  const [grade, setGrade] = useState("");
  const [house, setHouse] = useState("");

  useEffect(() => {
    action.handleSidebarKey("dailyQuiz");
  }, []);

  useEffect(() => {
    fetchData();
  }, [date]);

  useEffect(() => {
    applyFilters();
  }, [searchText, grade, house]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/quiz/students`,
      params: {
        date: date
          ? moment(date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      },
    })
      .then((res) => {
        setData(res.data);
        setFilteredData(res.data);
        setColumns(generateDynamicColumns(res.data));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const applyFilters = () => {
    let filtered = [...data];
    if (searchText) {
      filtered = filtered.filter(
        (item) =>
          item.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.code.toString().includes(searchText)
      );
    }
    if (grade) {
      filtered = filtered.filter((item) => item.grade === grade);
    }
    if (house) {
      filtered = filtered.filter((item) => item.houseId === house);
    }
    setFilteredData(filtered);
  };

  const generateDynamicColumns = (data) => {
    if (!data.length) return [];

    const staticColumns = [
      {
        title: "ID",
        dataIndex: "code",
        key: "code",
        fixed: true,
        width: 50,
        sorter: (a, b) => a.code - b.code,
      },
      {
        title: "Нэр",
        dataIndex: "firstName",
        key: "firstName",
        fixed: true,
        width: 150,
        render: (text, row) => <span>{row.lastName[0] + ". " + text}</span>,
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      },
      {
        title: "House",
        dataIndex: "houseId",
        key: "houseId",
        fixed: true,
        width: 50,
        sorter: (a, b) => a.houseId.localeCompare(b.houseId),
      },
      {
        title: "Анги",
        dataIndex: "grade",
        key: "grade",
        fixed: true,
        width: 50,
        sorter: (a, b) => a.grade - b.grade,
      },
      {
        title: "Төлөв",
        dataIndex: "status",
        key: "status",
        fixed: true,
        width: 100,
        sorter: (a, b) => a.status.localeCompare(b.status),
        filters: [
          { text: "Бөглөсөн", value: "Бөглөсөн" },
          { text: "Бөглөөгүй", value: "Бөглөөгүй" },
        ],
        onFilter: (value, record) => record.status === value,
        render: (status) => {
          return (
            <Tag color={status === "Бөглөсөн" ? "green" : "orange"}>
              {status}
            </Tag>
          );
        },
      },
    ];

    const dynamicColumns = Object.keys(data[0])
      .filter(
        (key) =>
          ![
            "code",
            "firstName",
            "lastName",
            "houseId",
            "grade",
            "status",
          ].includes(key)
      )
      .map((questionKey) => ({
        title: questionKey,
        dataIndex: questionKey,
        key: questionKey,
        // sorter: (a, b) => {
        //   if (a[questionKey] && b[questionKey]) {
        //     return a[questionKey].localeCompare(b[questionKey]);
        //   }
        //   return 0;
        // },
        render: (text) => <span>{text || ""}</span>,
      }));

    return [...staticColumns, ...dynamicColumns];
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Daily Quiz Report");
    XLSX.writeFile(wb, "daily_quiz_report.xlsx");
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5">
        <DatePicker value={date} onChange={(e) => setDate(e)} />

        <Input
          placeholder="Name or Code"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          allowClear
        />

        <Select
          placeholder="Сонгох анги"
          value={grade}
          onChange={(value) => setGrade(value)}
          allowClear
        >
          <Option value="">Бүгд</Option>
          <Option value="8">8-р анги</Option>
          <Option value="9">9-р анги</Option>
          <Option value="10">10-р анги</Option>
          <Option value="11">11-р анги</Option>
          <Option value="12">12-р анги</Option>
        </Select>

        <Select
          placeholder="Сонгох хаус"
          value={house}
          onChange={(value) => setHouse(value)}
          allowClear
        >
          <Option value="">Бүгд</Option>
          <Option value="Buhu">Buhu</Option>
          <Option value="Hawt">Hawt</Option>
          <Option value="Irve">Irve</Option>
          <Option value="Khant">Khant</Option>
          <Option value="Maza">Maza</Option>
          <Option value="Tsa">Tsa</Option>
          <Option value="Tsen">Tsen</Option>
          <Option value="Yang">Yang</Option>
        </Select>

        <Button icon={<SearchOutlined />} onClick={fetchData} />
        <Button
          icon={<FileExcelOutlined />}
          onClick={exportToExcel}
          style={{ backgroundColor: "green", color: "white" }}
        />
      </div>

      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={filteredData}
          columns={columns}
          loading={loading}
          // pagination={true}
          title={"Өдрийн Асуулгын Жагсаалт"}
        />
      </div>
    </div>
  );
}

export default DailyQuiz;
