import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Form as AntForm,
  Table,
  Tooltip,
  Tag,
  Modal,
  Select,
} from "antd";
import { SearchOutlined, ClearOutlined, EditOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import { CompanySelect } from "components";
import { Link } from "react-router-dom";

import { AuthContext } from "contexts";
import { useNavigate } from "react-router-dom";

function Quiz() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);

  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/quiz/list`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Гарчиг",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => navigate("/quiz-detail/"+row.id)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleAdd = () => {
  navigate("/quiz-detail")
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };
  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={true}
          title={"Quiz"}
          handleAdd={handleAdd}
          handleRefresh={fetchData}
        />
      </div>
      
    </div>
  );
}

export default Quiz;
