import { Button, Input, Select, Form, message,Spin } from "antd";
import React, { useEffect,useState } from "react";
import { EllipsisOutlined, DeleteOutlined } from "@ant-design/icons";
import TinyEditor from "components/TinyEditor";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { instance } from "utils/axios";
import { useParams } from "react-router-dom"; 
function QuizDetail() {
  const [form] = Form.useForm();
  const { id } = useParams(); 
 const [loading, setLoading] = useState(false);
  const questionTypes = [
    { label: "Short Answer", value: "short-answer" },
    { label: "Multiple Choice", value: "multiple-choice" },
    { label: "Paragraph", value: "paragraph" },
    { label: "Number", value: "number" },
    { label: "Range", value: "range" },
  ];
    useEffect(() => {
    if (id) {
      fetchQuizDetails(id);
    }
  }, [id]);
    const fetchQuizDetails = (quizId) => {
      setLoading(true);
      instance
        .get(`/quiz/detail/${quizId}`)
        .then((res) => {
        console.log(res.data)
          form.setFieldsValue({
            title: res.data.title,
            description: res.data.description,
            questions: res.data.questions.map((question, index) => ({
              ...question,
              orderIndex: index,
            })),
          });
        })
        .catch((error) => {
          message.error("Failed to load quiz details.");
        })
        .finally(() => {
          setLoading(false);
        });
    };

 const handleSubmit = (values) => {
   const processedQuestions = values.questions.map((question, index) => ({
     ...question,
     orderIndex: index,
     questionId: question.questionId || null, // Ensure questionId is set or null
     answers: question.answers
       ? question.answers.map((answer, answerIndex) => ({
           ...answer,
           answerId: answer.answerId || null, 
           orderIndex: answerIndex,
         }))
       : [],
   }));

   const payload = {
     id,
     title: values.title,
     description: values.description,
     questions: processedQuestions,
   };

   if (id) {
     instance
       .put(`/quiz/update/${id}`, payload)
       .then((res) => {
         message.success("Quiz updated successfully!");
   fetchQuizDetails(id);
       })
       .catch((error) => {
         message.error("Failed to update the quiz.");
       });
   } else {

     instance
       .post("/quiz/create", payload)
       .then((res) => {
         message.success("Quiz created successfully!");
         form.resetFields();
       })
       .catch((error) => {
         message.error("Failed to create the quiz.");
       });
   }
 };


  const handleDragEnd = (result, type, parentIndex = null) => {
    if (!result.destination) return;
    if (type === "questions") {
      const questions = form.getFieldValue("questions");
      const [reorderedQuestion] = questions.splice(result.source.index, 1);
      questions.splice(result.destination.index, 0, reorderedQuestion);
      form.setFieldsValue({ questions });
    } else if (type === "answers" && parentIndex !== null) {
      const questions = form.getFieldValue("questions");
      const answers = questions[parentIndex].answers || [];
      const [reorderedanswer] = answers.splice(result.source.index, 1);
      answers.splice(result.destination.index, 0, reorderedanswer);
      questions[parentIndex].answers = answers;
      form.setFieldsValue({ questions });
    }
  };

  const renderQuestionField = (type, name, index) => {
    switch (type) {
      case "short-answer":
        return null;
      case "multiple-choice":
        return (
          <Form.List name={[name, "answers"]} rules={[{ required: true }]}>
            {(fields, { add, remove }) => (
              <DragDropContext
                onDragEnd={(result) => handleDragEnd(result, "answers", index)}
              >
                <Droppable droppableId={`droppable-answers-${index}`}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {fields.map(({ key, name, fieldKey }, index) => (
                        <Draggable
                          key={key}
                          draggableId={`draggable-answer-${name}-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="flex items-center gap-2 group w-1/2"
                            >
                              <EllipsisOutlined
                                {...provided.dragHandleProps}
                                className="text-lg cursor-move opacity-0 group-hover:opacity-100 flex-shrink-0"
                              />
                              <Form.Item
                                name={[name, "answer"]}
                                fieldKey={[fieldKey, "answer"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Сонголт оруулна уу!",
                                  },
                                ]}
                                className="flex-grow mb-0"
                              >
                                <Input
                                  placeholder="Сонголт"
                                  className="w-full"
                                />
                              </Form.Item>
                              <DeleteOutlined
                                className="text-lg cursor-pointer flex-shrink-0"
                                onClick={() => remove(name)}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      <Button onClick={() => add()} block className="mt-2">
                        + Сонголт 
                      </Button>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Form.List>
        );
      case "paragraph":
        return null;
      case "number":
      case "range":
        return (
          <div className="space-y-4">
            <div className="flex gap-2">
              <Form.Item
                  name={[name, "minValue"]}
                rules={[{ required: true, message: "Гарчиг оруулна уу!" }]}
                style={{ width: "24%" }}
              >
                <Input type="number" placeholder="Min" />
              </Form.Item>
              <Form.Item
                   name={[name, "maxValue"]}
                style={{ width: "24%" }}
                rules={[{ required: true, message: "Гарчиг оруулна уу!" }]}
              >
                <Input type="number" placeholder="Max" />
              </Form.Item>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-[1000px] min-w-[700px] mx-auto">
      {loading ? (
        <Spin />
      ) : (
        <div className="bg-white px-4 mt-4">
          <div className="py-3 border-b">Санал асуулга үүсгэх</div>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            className="mt-3 gap-x-4"
          >
            <Form.Item
              label="Гарчиг"
              name="title"
              className="col-span-12 mb-2"
              rules={[{ required: true, message: "Гарчиг оруулна уу!" }]}
            >
              <Input placeholder="Гарчиг оруулах" />
            </Form.Item>
            <Form.Item
              label="Тайлбар"
              name="description"
              className="col-span-12 mb-2"
              // rules={[{ required: true, message: "Тайлбар оруулна уу!" }]}
            >
              <TinyEditor name="description" form={form} />
            </Form.Item>

            <Form.List name="questions" rules={[{ required: true }]}>
              {(fields, { add, remove }) => (
                <DragDropContext
                  onDragEnd={(result) => handleDragEnd(result, "questions")}
                >
                  <Droppable droppableId="droppable-questions">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {fields.map(({ name, key, fieldKey }, index) => (
                          <Draggable
                            key={key}
                            draggableId={`draggable-question-${name}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="border border-dashed rounded px-4 py-2 mt-3 group"
                              >
                                <div className="flex justify-between items-center">
                                  <div className="flex gap-2 items-center">
                                    <EllipsisOutlined
                                      {...provided.dragHandleProps}
                                      className="text-lg cursor-move opacity-0 group-hover:opacity-100"
                                    />
                                  </div>
                                </div>
                                <div className="flex items-center gap-2 mb-2">
                                  <Form.Item
                                    name={[name, "question"]}
                                    fieldKey={[fieldKey, "question"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Асуулт оруулна уу!",
                                      },
                                    ]}
                                    className="flex-grow mb-0"
                                  >
                                    <Input placeholder="Асуулт" />
                                  </Form.Item>
                                  <Form.Item
                                    name={[name, "qType"]}
                                    fieldKey={[fieldKey, "qType"]}
                                    initialValue="short-answer"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Төрлийг сонгоно уу!",
                                      },
                                    ]}
                                    className="mb-0 flex-none w-1/5"
                                  >
                                    <Select placeholder="Төрөл сонгох">
                                      {questionTypes.map((type) => (
                                        <Select.Option
                                          key={type.value}
                                          value={type.value}
                                        >
                                          {type.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                  <DeleteOutlined
                                    danger
                                    // onClick={() => remove(name)}
                                    className="flex items-center justify-center h-8 w-8 p-0 text-red-600"
                                  />
                                </div>

                                <Form.Item
                                  shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.questions?.[name]?.qType !==
                                    currentValues.questions?.[name]?.qType
                                  }
                                >
                                  {() =>
                                    renderQuestionField(
                                      form.getFieldValue([
                                        "questions",
                                        name,
                                        "qType",
                                      ]),
                                      name,
                                      index
                                    )
                                  }
                                </Form.Item>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        <Button onClick={() => add()} block className="my-4">
                          + Асуулт
                        </Button>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Form.List>

            <div className="col-span-12 flex justify-end gap-3 py-3">
              <Button type="primary" htmlType="submit">
                Хадгалах
              </Button>
              {/* <Button onClick={() => }>Хадгалах</Button> */}
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}

export default QuizDetail;
