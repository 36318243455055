import React, { useEffect, useState } from "react";
import { Button, Form as AntForm, Modal, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";

function Lesson() {
  const [data, setData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchCourses();
      fetchData();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      fetchData();
    }
  }, [selectedCourse]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/lessons`,
      // params: {
      //   courseId: selectedCourse,
      // },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const fetchCourses = () => {
    instance({
      method: "get",
      url: `/courses`,
    })
      .then((res) => {
        setCourses(res.data);
      })
      .catch((err) => {});
  };

  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const handleCourseChange = (value) => {
    setSelectedCourse(value);
  };

  const fields = [
    {
      label: "Нэр",
      name: "name",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Code",
      name: "code",
      rules: [{ required: true, message: "Code оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
      {
      label: "Duration /Minute/",
      name: "duration",
      type: "number",
      rules: [{ required: true, message: "хугацаа оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Тайлбар",
      name: "description",
      type: "textarea",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хичээл",
      name: "courseId",
      rules: [{ required: true, message: "Хичээл сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      type: "select",
      inputProps: {
        className: "w-full",
        options: courses.map((item) => ({ value: item.id, label: item.name })),
      },
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    const payload = {
      ...values,
      courseId: values.courseId,
    };

    if (editData) {
      instance({
        method: "put",
        url: `/lesson`,
        data: {
          ...payload,
          id: editData.id,
        },
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: `/lesson`,
        data: payload,
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Course",
      dataIndex: "courseId",
      key: "course",
      render: (text, row, i) => <span>{row.course.name}</span>,
    },
    {
      title: "Topic",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Learning object",
      dataIndex: "learningObject",
      key: "learningObject",
    },
    {
      title: "Required Skill (s)",
      dataIndex: "requiredSkill",
      key: "requiredSkill",
    },
    {
      title: "Тайлбар",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5">
        <Select
          className="col-span-12 md:col-span-3"
          placeholder="Хичээл сонгоно уу"
          onChange={handleCourseChange}
          value={selectedCourse}
          allowClear
        >
          {courses.map((course) => (
            <Select.Option key={course.id} value={course.id}>
              {course.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={true}
          title={"Хичээл"}
          handleAdd={handleAdd}
          handleRefresh={fetchData}
        />
      </div>
      <Modal
        title={editData ? "Сэдэв засах" : "Сэдэв засах"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Lesson;
