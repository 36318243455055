import React, { useEffect, useRef, useState, useContext } from "react";
import { Button, DatePicker, Avatar, List, Skeleton ,Tag,Select} from "antd";
import ColumnChart from "./ColumnChart";
import noPhoto from '../../../assets/images/no-photo.jpg'; 
import {
  SearchOutlined,
} from "@ant-design/icons";
import { instance } from "utils/axios";
import { AuthContext } from "contexts";
import moment from "moment";
const { RangePicker } = DatePicker;
function AttendanceDashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
      const { state, action } = useContext(AuthContext);
const [date1, setDate1] = useState(moment().startOf("month")); // Monday of current week
const [date2, setDate2] = useState(moment().endOf("month")); 
  useEffect(() => {
    fetchData();
  }, [date1,date2]);
  useEffect(()=>{
  action.handleSidebarKey("attendance-dashboard");
},[])

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/attendance/dashboard?date1=${
        date1
          ? moment(date1).format("yyyy-MM-DD")
          : moment().format("yyyy-MM-DD")
      }&date2=${
        date2
          ? moment(date2).format("yyyy-MM-DD")
          : moment().format("yyyy-MM-DD")
      }`,
    })
      .then((res) => {
       
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5">
        <RangePicker
          value={[date1, date2]}
          onChange={(dates) => {
            if (dates) {
              setDate1(dates[0]);
              setDate2(dates[1]);
            } else {
              setDate1(moment());
              setDate2(moment());
            }
          }}
        />
        <div>
          <Button icon={<SearchOutlined />} onClick={fetchData} />
        </div>
      </div>
      <div className="grid grid-cols-24 gap-2">
        {data?.statusData?.map((item) => (
          <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
            <div>
              <div className="text-base text-muted">{item.status}</div>
              <div className="font-medium text-xl">
                {Intl.NumberFormat().format(item.cnt)}
              </div>
            </div>
          </div>
        ))}
      </div>
      <ColumnChart
        data={data?.dateData}
        className="col-span-12"
        alias="Ирцийн тоо/Өдөр/"
        xField="attendanceDate"
        yField="cnt"
        start={0}
      />
      <div className="grid grid-cols-12 gap-5 mt-5">
        <div className="col-span-12 md:col-span-4 bg-white rounded border p-2">
          <h2> House</h2>
          <List
            itemLayout="horizontal"
            dataSource={data.houseData}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      className="bg-secondary p-1"
                      src={item?.googleLink}
                    />
                  }
                  title={item?.monName}
                />
                <div>
                  <Tag color="green">{item?.present}</Tag>
                  <Tag color="orange">{item?.late}</Tag>
                  <Tag color="red">{item?.absent}</Tag>
                  <Tag color="blue">{item?.excused}</Tag>
                  <Tag color="purple">{item?.sick}</Tag>
                </div>
              </List.Item>
            )}
          />
        </div>
        <div className="col-span-12 md:col-span-5 bg-white rounded border p-2">
          <h2> Хичээлдээ цөөн суусан сурагчид</h2>
          <List
            itemLayout="horizontal"
            dataSource={data.tenStudent}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      className="bg-secondary cover"
                      src={item?.imageUrl ? item?.imageUrl : noPhoto}
                    />
                  }
                  title={item?.fullName}
                />
                <div>
                  <Tag color="orange">{item?.late}</Tag>
                  <Tag color="red">{item?.absent}</Tag>
                  <Tag color="blue">{item?.excused}</Tag>
                  <Tag color="purple">{item?.sick}</Tag>
                </div>
              </List.Item>
            )}
          />
        </div>
        <div className="col-span-12 md:col-span-3 bg-white rounded border p-2">
          <h2> Ангиар</h2>
          <List
            itemLayout="horizontal"
            dataSource={data.classData}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta title={item?.grade} />
                <div>
                  <Tag color="success">{item?.present}</Tag>
                  <Tag color="orange">{item?.late}</Tag>
                  <Tag color="red">{item?.absent}</Tag>
                  <Tag color="blue">{item?.excused}</Tag>
                  <Tag color="purple">{item?.sick}</Tag>
                </div>
              </List.Item>
            )}
          />
        </div>
      </div>
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        {/* <MainTable
          dataTable={filteredData} // Use filtered data
          columns={columns} // Use dynamic columns
          loading={loading}
          title={"Ирцийн жагсаалт"}
        /> */}
      </div>
    </div>
  );
}

export default AttendanceDashboard;
