import {
  Badge,
  Button,
  Card,
  Checkbox,
  Modal,
  Tooltip,
  Form as AntForm,
} from "antd";
import { Form, MainTable } from "components";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { EditOutlined, MailOutlined, PrinterOutlined } from "@ant-design/icons";
import { FaChalkboardUser, FaCheckToSlot, FaUserDoctor } from "react-icons/fa6";
import { AuthContext } from "contexts";
import { render } from "less";
import { FaEnvelope, FaPrint } from "react-icons/fa";
import { instance } from "utils/axios";
import reportFields from "./reportFields";
import { useNavigate } from "react-router-dom";

const getStatusTagColor = (type) => {
  switch (type) {
    case "Хоцорсон":
      return "orange";

    case "Хэвийн":
      return "success";
  }
};

const { confirm } = Modal;

function StudentDetail({ data, rowData, refreshData }) {
  const [loading, setLoading] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  useEffect(() => {
    
  }, []);
  const columns = [
    {
      title: "Төлөх өдөр",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "Төлөх дүн",
      dataIndex: "doctorName",
      key: "doctorName",
    },
    {
      title: "Төлөв",
      dataIndex: "technicalName",
      key: "technicalName",
    },
    {
      title: "Төлсөн өдөр",
      dataIndex: "doctorName",
      key: "doctorName",
    },
    {
      title: "Төлсөн дүн",
      dataIndex: "doctorName",
      key: "doctorName",
    },
  ];


  return (
    <Badge.Ribbon
      text={rowData?.paymentStatus}
      color={getStatusTagColor(rowData?.paymentStatus)}
    >
      <Card>
        <div className="text-lg mb-3">Сурагчийн мэдээлэл</div>
        <div className="flex flex-col mb-2 text-xs gap-1">
          <div className="flex items-center gap-2">
            <div className="text-gray-400">Овог нэр:</div>
            <div>
              {rowData?.lastName} {rowData?.firstName}
            </div>
          </div>
          <div className="flex gap-5">
            <div className="flex items-center gap-2">
              <div className="text-gray-400">House:</div>
              <div>{data?.house ? data?.house.name : ""}</div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-gray-400">Утас:</div>
            <div>{data?.mobile}</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-gray-400">И-мэйл:</div>
            <div>{data?.email}</div>
          </div>
        </div>
        <div className="mb-2 text-xs">
          <div>Тэмдэглэл:</div>
          <div className="border rounded px-4 py-2 bg-gray-100">
            {data?.note}
          </div>
        </div>

        <MainTable
          columns={columns}
          dataTable={data?.bookingservices}
          title={<div className="text-base">Төлбөрийн график</div>}
        />
        {/* <div className="flex flex-col items-end mt-6 text-xs">
          <div className="flex items-center gap-2">
            <div className="text-gray-400">Бүртгэсэн ажилтан:</div>
            <div>{data?.technicalUser}</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="text-gray-400">Бүртгэсэн огноо:</div>
            <div>{moment(data?.createdAt).format("YYYY-MM-DD HH:mm")}</div>
          </div>
        </div> */}
      </Card>

      {contextHolder}
    </Badge.Ribbon>
  );
}

export default StudentDetail;
