import Reacyarnt, { useEffect, useRef } from "react";
import { UploadOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import {
  Form,
  Input,
  Collapse,
  Tabs,
  DatePicker,
  Select,
  Upload,
  Button,
  InputNumber,
  Divider,
  TreeSelect,
  Switch,
  Checkbox,
  TimePicker,
} from "antd";
import ISelect from "components/Select";
import Map from "components/Map";
import TinyEditor from "components/TinyEditor";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";

const { Panel } = Collapse;

const CustomForm = ({
  fields,
  className,
  editData,
  length,
  children,
  ...restProps
}) => {
  const editorRef = useRef(null);
  const [antForm] = Form.useForm();
  const form = restProps.form || antForm;
  useEffect(() => {
    // if (!editData.isAdd) {
    form.setFieldsValue(editData);
    fields.map((item) => {
      if (item) {
        if (item.type === "date") {
          if (editData?.bookingData != null) {
            form.setFieldValue(
              item.name,
              moment(editData.bookingData[item.name[1]])
            );
          } else {
            if (editData != null)
              form.setFieldValue(item.name, moment(editData[item.name]));
          }
        }
        if (item.type === "time") {
          form.setFieldValue(
            item.name,
            moment(
              moment(editData.bookingData[item.name[1]]).format(
                "YYYY-MM-DD HH:mm"
              )
            )
          );
        }
      }
    });
    // }
  }, [editData]);

  const getFillPercent = (fields) => {
    let values = form.getFieldsValue();
    let filled = 0;
    let percent = 0;
    if (fields) {
      fields.map((field) => {
        if (values[`${field.name}`]) {
          filled += 1;
        }
      });
      percent = parseInt((filled * 100) / fields.length);
    }
    return `${percent} %`;
  };

  const renderFormItem = (_field, _fieldIndex) => {
    if (_field)
      switch (_field.type) {
        case "collapse":
          return (
            <Collapse
              defaultActiveKey={["0"]}
              key={`form-collapse-${_fieldIndex}`}
              {..._field}
            >
              {_field.children?.map((item, itemIndex) => (
                <Panel
                  key={`${itemIndex}`}
                  header={
                    <Form.Item
                      className="mb-0"
                      shouldUpdate={(pre, cur) =>
                        pre[item.name] !== cur[item.name]
                      }
                    >
                      <span>
                        {item.label} - {getFillPercent(item.children)}
                      </span>
                    </Form.Item>
                  }
                >
                  <div className="grid grid-cols-12 gap-4">
                    {item.children &&
                      item.children.map((child, childIndex) =>
                        renderFormItem(child, childIndex)
                      )}
                  </div>
                </Panel>
              ))}
            </Collapse>
          );
        case "tabs":
          return <Tabs></Tabs>;
        case "editor":
          return (
            // <Form.Item name="content" {..._field}>
            //   <TinyEditor editData={editData} name={_field.name} disabled={restProps.disabled} form={form} {..._field.inputProps} />
            // </Form.Item>
            <Form.Item name={_field.name} {..._field}>
              <Editor
                onInit={(e, editor) => (editorRef.current = editor)}
                apiKey="a22ry67l2lvwvfa9lvrr2yeeih0vpyw7brx1cc0g2lbm41rx"
                init={{
                  init_instance_callback: function (editor) {
                    editor.on("Change", function (e) {
                      // form.setFieldValue(_field.name, e.level.content)
                    });
                  },
                  selector: "#editor",
                  height: "300px",
                  width: "100%",
                  branding: false,
                  menubar: false,
                  font_size_formats:
                    "8px 9px 10px 11px 12px 14px 18px 24px 36px",
                  plugins: [
                    "advlist autolink lists link image",
                    "charmap print preview anchor help",
                    "searchreplace visualblocks code",
                    "insertdatetime media table paste wordcount twitter",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic | fontsize | media | image |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | help",
                  content_style: " body {font-size: 12px}",
                  // formats: {
                  //   customtwitter: { inline: 'span', styles: {backgroundColor: '#e9ebe4'}, classes: 'shareable', id: 'ww' }
                  // },
                  // style_formats: [
                  //   { title: 'My PDF file', selector: 'a', classes: 'mypdffile' },
                  //   { title: 'Twitter format', format: 'customtwitter'}
                  // ],
                  // file_picker_callback: function (callback, value, meta) {
                  //   var input = document.createElement('input');
                  //   input.setAttribute('type', 'file');
                  //   input.setAttribute('accept', 'image/*');
                  //   input.onchange = function () {
                  //     var file = this.files[0];
                  //     var reader = new FileReader();
                  //     reader.onload = function (e) {
                  //       callback(e.target.result, {
                  //         alt: file.name
                  //     });

                  //     };
                  //     reader.readAsDataURL(file);
                  //   };
                  //   input.click();
                  // },
                }}
                onEditorChange={(e, editor) => {
                  form.setFieldValue(_field.name, e);
                }}
              />
            </Form.Item>
          );
        case "dependent":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              noStyle
              shouldUpdate={(preValue, curValue) =>
                preValue[_field.dependentName] !==
                curValue[_field.dependentName]
              }
              className={_field.className}
            >
              {({ getFieldValue }) => (
                <>
                  {getFieldValue(_field.dependentName) &&
                  getFieldValue(_field.dependentName) === _field.dependentValue
                    ? _field.children &&
                      _field.children.map((child, childIndex) =>
                        renderFormItem(child, childIndex)
                      )
                    : null}
                </>
              )}
            </Form.Item>
          );
        case "date":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <DatePicker className="w-full" />
            </Form.Item>
          );
        case "time":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <TimePicker
                format="HH:mm"
                className="w-full"
                changeOnBlur
                onSelect={(e) => form.setFieldValue(_field.name, e)}
              />
            </Form.Item>
          );
        case "select":
          if (_field.dependentIndex) {
            return (
              <Form.Item
                noStyle
                shouldUpdate={(preValue, curValue) =>
                  preValue[_field.dependentIndex] !==
                  curValue[_field.dependentIndex]
                }
                className={_field.className}
              >
                {({ getFieldValue, setFieldValue }) => {
                  return (
                    <Form.Item
                      key={`form-item-${_field.name}-${_fieldIndex}`}
                      {..._field}
                    >
                      <ISelect
                        setFieldValue={setFieldValue}
                        getFieldValue={getFieldValue}
                        _field={_field}
                        optionsUrl={_field.inputProps.optionsUrl}
                        dependentValue={getFieldValue(_field.dependentIndex)}
                        placeholder={`${_field.label} Select`}
                        {..._field.inputProps}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            );
          } else {
            if (_field.name === "status" && !editData) {
              form.setFieldValue("status", 1);
            }
            return (
              <Form.Item
                key={`form-item-${_field.name}-${_fieldIndex}`}
                {..._field}
              >
                <Select {..._field.inputProps} />
              </Form.Item>
            );
          }
        case "inputSelect":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.text.toLowerCase().includes(input.toLowerCase())
                }
                {..._field.inputProps}
              />
            </Form.Item>
          );
        case "treeSelect":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <TreeSelect {..._field.inputProps} />
            </Form.Item>
          );
        case "map":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <Map
                {..._field.inputProps}
                value={form.getFieldValue(_field.name)}
                onChange={form.setFieldValue}
                disabled={restProps.disabled}
              />
            </Form.Item>
          );
        case "file":
          return (
            <div className={`flex gap-5  items-start ${_field.className}`}>
              {editData && editData[_field.name] ? (
                <img
                  className="max-w-[100px]"
                  src={`${process.env.REACT_APP_CDN_URL}${
                    editData[_field.name]
                  }`}
                />
              ) : null}
              <Form.Item
                valuePropName="file"
                key={`form-item-${_field.name}-${_fieldIndex}`}
                {..._field}
              >
                <Upload beforeUpload maxCount={1} {..._field.inputProps}>
                  <Button icon={<UploadOutlined />}>Зураг оруулах</Button>
                </Upload>
              </Form.Item>
            </div>
          );
        case "listFile":
          return (
            <Form.Item
              valuePropName="file"
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <Upload beforeUpload {..._field.inputProps}>
                <Button icon={<UploadOutlined />}>Файл оруулах</Button>
              </Upload>
            </Form.Item>
          );
        case "password":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <Input.Password />
            </Form.Item>
          );
        case "switch":
          return (
            <Form.Item
              valuePropName="checked"
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <Switch />
            </Form.Item>
          );
        case "textarea":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <Input.TextArea
                placeholder={`${_field.label}`}
                {..._field.inputProps}
              />
            </Form.Item>
          );
        case "number":
          if (_field.name === "orderid" && !editData) {
            form.setFieldValue(_field.name, length + 1);
          }
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <InputNumber
                controls={false}
                placeholder={`${_field.label}`}
                {..._field.inputProps}
              />
            </Form.Item>
          );
        case "price":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <InputNumber
                controls={false}
                formatter={(value) =>
                  `${new Intl.NumberFormat("en-US").format(value)}`
                }
                placeholder={`${_field.label}`}
                {..._field.inputProps}
              />
            </Form.Item>
          );
        case "percent":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <InputNumber
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
                placeholder={`${_field.label} оруулах`}
                {..._field.inputProps}
              />
            </Form.Item>
          );
        case "checkbox":
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              valuePropName="checked"
              {..._field}
              label=""
            >
              <Checkbox {..._field.inputProps}>{_field.label}</Checkbox>
            </Form.Item>
          );
        case "component":
          return _field.component;
        case "divider":
          return (
            <Form.Item {..._field}>
              <Divider key={`divider-${_field.label}`} {..._field.inputProps}>
                {_field.text}
              </Divider>
            </Form.Item>
          );
        default:
          return (
            <Form.Item
              key={`form-item-${_field.name}-${_fieldIndex}`}
              {..._field}
            >
              <Input placeholder={`${_field.label}`} {..._field.inputProps} />
            </Form.Item>
          );
      }
    else return <></>;
  };

  return (
    <Form className={`grid grid-cols-12 ${className}`} {...restProps}>
      {fields.map((item, itemIndex) => renderFormItem(item, itemIndex))}
      {children}
    </Form>
  );
};

CustomForm.propTypes = {
  fields: PropTypes.array,
};

CustomForm.defaultProps = {
  fields: [],
};
CustomForm.List = Form.List;
CustomForm.Item = Form.Item;

export default CustomForm;
