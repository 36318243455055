import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { instance } from 'utils/axios'
import { LoadingOutlined, PrinterOutlined } from '@ant-design/icons'
import './index.css'
import moment from 'moment'

function Print() {
  const [ data, setData ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const { guid } = useParams()

  useEffect(() => {
    getData()
  },[])

  const getData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: `bookingservice/print/${guid}`
    }).then((res) => {
      setData(res.data)
    }).catch((err) => {

    }).then(() => setLoading(false))
  }

  const handlePrint = () => {
    window.print()
  }

  return (
    <div className=' min-h-screen min-w-screen bg-gray-600 flex'>
      
      {
        loading ? 
        <div className='flex-1 flex justify-center items-center'>
          <LoadingOutlined className='text-[24px]'/>
        </div>
        :
        <div className=' w-[700px] mx-auto bg-white p-[20px] relative'>
          <button onClick={handlePrint} className='noprint absolute top-[20px] -right-[113px] shadow-xl bg-white border rounded-md p-2 hover:bg-gray-200 transition-all cursor-pointer'>
            <PrinterOutlined className='text-[24px]'/> Хэвлэх
          </button>
          <div className='text-center font-bold text-lg'>ДҮРС ОНОШИЛГООНЫ ШИНЖИЛГЭЭНИЙ ХАРИУ </div>
          <table className='w-full border-collapse border border-slate-400 mb-6 text-[13px]'> 
            <tbody>
              <tr>
                <td className='border border-slate-400 font-bold'>Бүртгэлийн дугаар: </td>
                <td className='border border-slate-400 text-center'>{data?.customerbookings[0]?.bookingservices[0]?.id}</td>
                <td className='border border-slate-400 font-bold' colSpan={2}>Овог нэр: </td>
                <td className='border border-slate-400 text-center' colSpan={2}>{data?.lastname} {data?.firstname}</td>
              </tr>
              <tr>
                <td className='border border-slate-400 font-bold'>Төрсөн он сар өдөр: </td>
                <td className='border border-slate-400 text-center'>{data?.dob}</td>
                <td className='border border-slate-400 font-bold'>Нас: </td>
                <td className='border border-slate-400 text-center'>{moment().diff(data?.dob, 'years')}</td>
                <td className='border border-slate-400 font-bold'>Хүйс: </td>
                <td className='border border-slate-400 text-center'>{data?.gender === 1 ? 'Эрэгтэй' : 'Эмэгтэй'}</td>
              </tr>
              <tr>
                <td className='border border-slate-400 font-bold'>Шинжилгээнд орсон огноо/цаг: </td>
                <td className='border border-slate-400 text-center'>{data?.customerbookings[0]?.bookingservices[0]?.technicalDate}</td>
                <td className='border border-slate-400 font-bold' colSpan={3}>Хариу бичсэн огноо/цаг: </td>
                <td className='border border-slate-400 text-center'>{data?.customerbookings[0]?.bookingservices[0]?.doctorDate}</td>
              </tr>
              <tr>
                <td className='border border-slate-400 font-bold px-1' colSpan={1}>Шинжилгээний нэр:</td>
                <td className='border border-slate-400 px-1' colSpan={5}>{data?.customerbookings[0]?.bookingservices[0]?.serviceName}</td>
              </tr>
            </tbody>
          </table>

          <div className='border border-slate-400 p-4 leading-none mb-6'> 
          {console.log('zxczczcx', data?.customerbookings[0]?.bookingservices[0]?.response)}
            <div dangerouslySetInnerHTML={{__html: data?.customerbookings[0]?.bookingservices[0]?.response}}></div>
          </div>

          <table className='w-full border-collapse border border-slate-500 text-[13px]'> 
            <tbody>
              <tr>
                <td className='border border-slate-400 font-bold px-1'>
                  Хариу бичсэн эмч 1
                </td>
                <td className='border border-slate-400 px-1'>
                  {data?.customerbookings[0]?.bookingservices[0]?.doctorName}
                </td>
              </tr>
            </tbody>
          </table>
          <div className='text-[12px] text-end mt-5'><span className='font-bold'>Хэвлэсэн огноо:</span> {moment().format('YYYY-MM-DD HH:mm:ss')}</div>
        </div>
      }
    </div>
  )
}

export default Print