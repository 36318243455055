import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Checkbox, message,Select, Form as AntForm } from "antd";
import { instance } from "utils/axios";
import { Form, MainTable } from "components";
export default function GroupManagement() {
  const [groups, setGroups] = useState([]);
  const [students, setStudents] = useState([]);
  // const [groupedStudents, setGroupedStudents] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [data, setData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [grade, setGrade] = useState("");
  const [house, setHouse] = useState("");
  const [groupId, setGroupId] = useState();
  const [form] = AntForm.useForm();
  const [editForm] = AntForm.useForm(); 

  useEffect(() => {
    fetchGroups();
    // fetchStudents();
  }, []);
  useEffect(() => {
    if(groupId)
       fetchGroupedStudents ()
  }, [grade,searchText,grade,groupId,house]);
  const fetchGroups = () => {
    setLoading(true);
    instance({
      method: "get",
      url: "/groups",
    })
      .then((res) => {
        setGroups(res.data);
      })
      .catch((err) => {
        message.error("Failed to load groups");
      })
      .finally(() => setLoading(false));
  };
  const fetchGroupedStudents = () => {
    setLoading(true);
   instance({
     method: "get",
     url: `/groupusers/${groupId}?grade=${grade}&houseId=${house}&searchText=${searchText}`,
   })
     .then((res) => {
       // console.log(res.data)
       setStudents(res.data);
     })
     .catch((err) => {
       message.error("Failed to load students");
     })
     .finally(() => setLoading(false));
    
  };

  const handleGroupSelect = (data) => {
    // setSelectedGroup(data.id);
    setGroupId(data.id);
    // fetchGroupedStudents(data.id);
  };

 

  const handleCheck = (check,row) => {
    // console.log(check)
      setLoading(true);
    if(check){
      instance({
        method: "post",
        url: `/groupuser/add`,
        data: {
          studentId: row.studentId,
          groupId: groupId,
        },
      })
        .then(() => {
          fetchGroupedStudents(groupId);
       
        })
        .catch((err) => {
          message.error("Failed ");
        })
        .finally(() => setLoading(false));
    }
    else{
       instance({
         method: "delete",
         url: `/groupusers/${row.groupUserId}`,
       })
         .then(() => {
           fetchGroupedStudents(groupId);
         })
         .catch((err) => {
           message.error("Failed");
         })
         .finally(() => setLoading(false));
    }
  };

  const handleSubmit = (values) => {
    if (editData) {
      instance({
        method: "put",
        url: `/group`,
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
        fetchGroups()
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: `/group`,
        data: values,
      })
        .then((res) => {
         fetchGroups();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };


const handleCloseModal = () => {
  setEditData(null);
  setOpen(false);
  form.resetFields();
};

  const columnsGroups = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "action",
      render: (text, row) => (
        <div className="space-x-2">
          <Button
            onClick={() => {
              setEditData(row);
              setEditOpen(true);
              editForm.setFieldsValue(row);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
    const fields = [
      {
        label: "Name",
        name: "name",
        rules: [{ required: true, message: "Овог оруулна уу!" }],
        className: "col-span-12 md:col-span-6 mb-0",
        inputProps: {
          className: "w-full",
        },
      },
      {
        label: "Code",
        name: "code",
        rules: [{ required: true, message: "КОд оруулна уу!" }],
        className: "col-span-12 md:col-span-6 mb-0",
        inputProps: {
          className: "w-full",
        },
      },
      {
        label: "Description",
        name: "description",
        className: "col-span-12  mb-0",
        type:"textarea",
        inputProps: {
          className: "w-full",
        },
      },
    ];

  const columnsGroupedStudents = [
    {
      title: "Овог нэр",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Код",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "House",
      dataIndex: "houseId",
      key: "houseId",
    },

    {
      title: "group",
      key: "groupId",
      render: (text, row) => (
      
        <Checkbox
          checked={row.groupUserId != null}
          onChange={(e) => {
            handleCheck(e.target.checked, row);
          }}
        ></Checkbox>
      ),
    },
  ];
 const handleAdd = () => {
   setEditData(null);
   setOpen(true);
 };
// const changeSelectKey =(value)=>{
//   console.log(value)
//   handleGroupSelect

// }
  return (
    <div className="m-4 bg-white p-4 rounded-lg shadow">
      {/* <h1 className="text-center mb-4">Study group</h1> */}
      <div className="grid grid-cols-3 md:grid-cols-6 gap-2 mb-2">
        <Input
          placeholder="Name or Code"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          allowClear
        />
        <Select
          placeholder="Сонгох анги"
          value={grade}
          onChange={(value) => setGrade(value)}
          allowClear
        >
          <Select.Option value="">Бүгд</Select.Option>
          <Select.Option value="8">8-р анги</Select.Option>
          <Select.Option value="9">9-р анги</Select.Option>
          <Select.Option value="10">10-р анги</Select.Option>
          <Select.Option value="11">11-р анги</Select.Option>
          <Select.Option value="12">12-р анги</Select.Option>
        </Select>

        <Select
          placeholder="Сонгох хаус"
          value={house}
          onChange={(value) => setHouse(value)}
          allowClear
        >
          <Select.Option value="">Бүгд</Select.Option>
          <Select.Option value="Buhu">Buhu</Select.Option>
          <Select.Option value="Hawt">Hawt</Select.Option>
          <Select.Option value="Irve">Irve</Select.Option>
          <Select.Option value="Khant">Khant</Select.Option>
          <Select.Option value="Maza">Maza</Select.Option>
          <Select.Option value="Tsa">Tsa</Select.Option>
          <Select.Option value="Tsen">Tsen</Select.Option>
          <Select.Option value="Yang">Yang</Select.Option>
        </Select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <MainTable
          columns={columnsGroups}
          setSelectedKeys={handleGroupSelect}
          dataTable={groups}
          loading={loading}
          rowKey="id"
          pagination={true}
          title={"Study group"}
          handleAdd={handleAdd}
          rowSelectionSingleShow={true}
          // handleRefresh={}
        />
        <MainTable
          columns={columnsGroupedStudents}
          dataTable={students}
          loading={loading}
          rowKey="id"
          bordered
          title={() =>
            selectedGroup ? `${selectedGroup.name} Group Students` : "Students"
          }
        />
      </div>

      <Modal
        title={editData ? "Групын мэдээлэл засах" : "Шинэ групп"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          // isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}
