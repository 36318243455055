import React, { useEffect, useState, useContext } from "react";
import { Button, Form as AntForm, Table, DatePicker,Select, Tag, Modal } from "antd";
import { SearchOutlined, ClearOutlined, EditOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import moment from "moment";

import { Link } from "react-router-dom";

import { AuthContext } from "contexts";

function Transaction() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sumData, setSumData] = useState([]);
  const [date, setDate] = useState(moment());
  const { state, action } = useContext(AuthContext);
  const authContext = useContext(AuthContext);
  const aaa =
    authContext.state.userInfo.profile.firstName == "Admin"?1:-1;
  const [method, setMethod] = useState(aaa);
  // useEffect(() => {
  //   fetchData();
  // }, [currentPage]);

  useEffect(() => {
    fetchData();
  }, [method,date]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/transactions?method=${method}&date=${date}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    instance({
      method: "get",
      url: `/transaction/sum?method=${method}&date=${date}`,
    })
      .then((res) => {
        console.log(res.data);
        setSumData(res.data[0]);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Огноо",
      dataIndex: "tnxDate",
      key: "tnxDate",
    },
    {
      title: "p2pId",
      dataIndex: "p2pId",
      key: "p2pId",
    },
{
      title: "Гүйлгээний утга",
      dataIndex: "tnxDescription",
      key: "tnxDescription",
    },

    {
      title: "Утасны дугаар",
      dataIndex: "notiPhone",
      key: "notiPhone",
    },
    {
      title: "Нийт дүн",
      dataIndex: "tnxAmount",
      key: "tnxAmount",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },
    {
      title: "Qpay шимтгэл",
      dataIndex: "fee",
      key: "fee",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },
    {
      title: "Төлсөн дүн",
      dataIndex: "amount",
      key: "amount",
      render: (text, row) => <span>{Intl.NumberFormat().format(text)} ₮</span>,
    },
  ];
  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
        
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5 ">
        <DatePicker picker="month" value={date} onChange={(e) => setDate(e)} />
        <div>
          <Select
            className="mb-0 w-[100px]"
            disabled={aaa==1}
            value={method}
            options={[
              {label:"Бүгд",value:-1},
            {
              label:"TAS",
              value:2
            },{
              label:"TA",
              value:1
            }
            ]}
            onChange={(e) => {
              setMethod(e);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-24 gap-2">
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Нийт</div>
            <div className="font-medium text-xl">
              {Intl.NumberFormat().format(sumData.tnxAmount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Шимтгэл</div>
            <div className="font-medium text-xl">
              {Intl.NumberFormat().format(sumData.fee ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-green-700 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Төлсөн</div>
            <div className="font-medium text-xl">
              {Intl.NumberFormat().format(sumData.amount ?? 0)}
            </div>
          </div>
        </div>
      </div>
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={true}
          title={"Гүйлгээ"}
        />
      </div>
    </div>
  );
}

export default Transaction;
