import {
  BiUserCheck,
  BiIdCard,
  BiCog,
  BiGrid,
  BiBuilding,
  BiHorizontalLeft,
  BiHorizontalRight,
  BiCalendarPlus,
  BiDialpad,
  BiUserCircle,
  BiTransferAlt,
  BiBarChartAlt,
  BiDialpadAlt,
} from "react-icons/bi";
import { DashboardOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AuthContext } from "contexts";

export default [
  {
    label: "Элсэлт",
    // type: "group",
    role: ["Админ", "Удирдлага", "Менежер"],

    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/admissions/dashboard">Хянах самбар</Link>,
        icon: <DashboardOutlined size={16} className="inline text-[#F19920]" />,
        key: "admissions-dahboard",
        role: ["Админ", "Удирдлага", "Менежер"],
      },
      {
        label: <Link to="admissions/applicants">Applicants</Link>,
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
        key: "applicants",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан"],
      },
    ],
  },
  {
    label: "Төлбөр",
    // type: "group",
    role: ["Админ", "Удирдлага", "Менежер"],

    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/tuition/dashboard">Хянах самбар</Link>,
        icon: <DashboardOutlined size={16} className="inline text-[#F19920]" />,
        key: "tuition-dahboard",
        role: ["Админ", "Удирдлага", "Менежер"],
      },
      {
        label: <Link to="/tuition/payers">Сурагчид</Link>,
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
        key: "tuition-payers",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан"],
      },
      {
        label: <Link to="/essay/transaction">Бүх гүйлгээ</Link>,
        icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
        key: "transactions",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан"],
      },
      {
        label: <Link to="/tuition/transactions">Төлбөрийн гүйлгээ</Link>,
        icon: <BiGrid size={16} className="inline text-[#F19920]" />,
        key: "tuition-bankTransaction",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан"],
      },
    ],
  },
  {
    label: "------",
    type: "group",
    role: ["Админ", "Удирдлага", "Менежер", "Багш"],
  },
  {
    label: "Хичээл",
    // type: "group",
    role: ["Админ", "Удирдлага", "Менежер", "Багш"],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/course">Course</Link>,
        key: "course",
        role: ["Админ", "Удирдлага", "Менежер", "Багш"],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
      {
        label: <Link to="/lesson">Lesson</Link>,
        key: "lesson",
        role: ["Админ", "Удирдлага", "Менежер", "Багш"],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
      {
        label: <Link to="/facility">Facility</Link>,
        key: "facility",
        role: ["Админ", "Удирдлага", "Менежер", "Багш"],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
      {
        label: <Link to="/group">Study Groups</Link>,
        key: "group",
        role: ["Админ", "Удирдлага", "Менежер", "Багш"],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
      {
        label: <Link to="/assignment">Assignment</Link>,
        key: "assignment",
        role: ["Админ", "Удирдлага", "Менежер", "Багш"],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
    ],
  },
  {
    label: <Link to="/schedule">Хуваарь</Link>,
    key: "schedule",
    role: ["Админ", "Удирдлага", "Менежер"],
    icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
  },
  {
    label: "Үйл ажиллагаа",
    type: "group",
    role: ["Админ", "Удирдлага", "Менежер", "Багш"],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/attendance/dashboard">Dashboard</Link>,
        icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
        key: "attendance-dashboard",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан", "Багш"],
      },
      {
        label: <Link to="/student/attendance">Ирц</Link>,
        icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
        key: "attendances",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан", "Багш"],
      },
      {
        label: <Link to="/attendance/report">Жагсаалт</Link>,
        icon: <BiBarChartAlt size={16} className="inline text-[#F19920]" />,
        key: "attendancesreport",
        role: ["Админ", "Удирдлага", "Менежер", "Нягтлан", "Багш"],
      },
      {
        label: <Link to="/quiz">Quizs</Link>,
        key: "quizs",
        icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
        role: ["CEO", "Админ", "Менежер", "Багш"],
      },
      {
        label: <Link to="/daily-quiz">Daily Quizs</Link>,
        key: "quiz_answers",
        icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
        role: ["CEO", "Админ", "Менежер", "Багш"],
      },
    ],
  },
  {
    label: "Хүний нөөц",
    // type: "group",
    role: ["Админ", "Удирдлага", "Менежер"],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/employee">Ажилчид</Link>,
        key: "employee",
        role: ["Админ", "Удирдлага", "Менежер"],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
    ],
  },
];
