import React, { useEffect, useState, useContext } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import {
  Button,
  Tag,
  Modal,
  Select,
  Input,
  DatePicker,
  Form as AntForm,
} from "antd";
import { Form } from "components";
import moment from "moment";
import "moment/locale/mn"; // Import Mongolian locale for moment
import { AuthContext } from "contexts";
import { instance } from "utils/axios";
import ScheduleForm from "./scheduleForm";

moment.locale("mn"); // Set moment to use Mongolian locale

const scheduleTypes = [
  { value: "all", label: "All" },
  { value: "lesson", label: "Lesson", color: "green" },
  { value: "assignment", label: "Assignment", color: "orange" },
  { value: "meeting", label: "Meeting", color: "pink" },
];

export default function ScheduleManagement() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [scheduleType, setScheduleType] = useState("All"); // Schedule төрлийг сонгох
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [modal, contextHolder] = Modal.useModal();
  const [form] = AntForm.useForm();
  const getTagColor = (type) => {
    const st = scheduleTypes.find((item) => item.value == type);
    return st ? st.color : "default";
  };
  useEffect(() => {
    action.handleSidebarKey("schedule");

    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/schedules`,
    })
      .then((res) => {
        setData(
          res.data.map((item) => ({
            data: item,
            id: item.id,
            title: `${item.title}`,
            start: item.startTime,
            end: item.endTime,
            type: item.type,
          }))
        );
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const handleDateClick = (arg) => {
    setEditData({
      startTime: arg.date,
      endTime: moment(arg.date).add(1, "hour").toDate(),
    });
    setOpen(true);
  };

  const handleEventClick = (clickInfo) => {
    let data = clickInfo.event.extendedProps.data;
    setEditData(data);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const payload = {
      ...values,
      startTime: moment(values.startTime).toISOString(),
      endTime: moment(values.endTime).toISOString(),
      scheduleType, // Schedule төрлийг хамруулна
    };

    if (editData && editData.id) {
      instance({
        method: "put",
        url: `/schedule`,
        data: payload,
      })
        .then(() => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: `/schedule`,
        data: payload,
      })
        .then(() => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const renderEventContent = (eventInfo) => {
    const data = eventInfo.event.extendedProps.data;
    let color  =getTagColor(data.type)
    console.log(data)
    //  console.log(eventInfo.event.extendedProps.data);

    return (
      <>
        <Tag className="w-full whitespace-normal break-words p-2" color={color}>
          {/* {eventInfo.event.title} */}
          <b>{eventInfo.event.title}</b>

          <br></br>
          <Tag color={color}>
            {data.startTime
              ? moment(data.startTime, "HH:mm").format("HH:mm")
              : ""}
          </Tag>
          <span className="text-orange-500 mr-1"> {data.facility.name}</span>
          <Tag color="pink"> {data.study_group.code}</Tag>
        </Tag>
      </>
    );
  };
  const renderEventContent2 = (eventInfo) => {
    const data = eventInfo.event.extendedProps.data;

    return (
      <>
        <Tag
          style={{
            width: "100%",
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
          color={data.color}
          onClick={() => handleEventClick(eventInfo)}
        >
          <b>
            {eventInfo.event.title} |{data.typeName}{" "}
          </b>

          <br></br>
          <span
            style={{
              width: "100%",
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            <Tag color={data.color}>
              {data.startTime
                ? moment(data.startTime, "HH:mm").format("HH:mm")
                : ""}
              -
              {data.endTime
                ? moment(data.endTime, "HH:mm").format("HH:mm")
                : ""}
            </Tag>
            <Tag
              style={{
                // width: "100%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
              color={data.color}
            >
              DS:{data.diagnose}
            </Tag>{" "}
            <Tag
              style={{
                width: "100%",
                whiteSpace: "normal",
                wordWrap: "break-word",
              }}
              color={data.color}
            >
              Мэс заслын нэр:{data.operation}
            </Tag>
            <Tag color={data.color}>{data.phone}</Tag>
            {data.doctors.map((doctor, index) => (
              <span key={index}>
                {doctor.lastName.charAt(0)}.{doctor.firstName},
              </span>
            ))}
            <br></br>
            {data.description}
          </span>
        </Tag>
      </>
    );
  };

  return (
    <div className="m-4 bg-white p-4 rounded-lg shadow ">
      <h1 className="text-center mb-4">Хуваарь Удирдлага</h1>
      <Select
        className="mb-4 w-full md:w-1/6"
        placeholder="Төрөл сонгоно уу"
        value={scheduleType}
        options={scheduleTypes}
        onChange={(value) => setScheduleType(value)}
      />
      <div className="m-2 h-[70vh]">
        <FullCalendar
          height="100%"
          firstDay={1}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            momentPlugin,
          ]}
          initialView="dayGridMonth"
          locale="mn"
          buttonText={{
            today: "Өнөөдөр",
            month: "Сар",
            week: "7 Хоног",
            day: "Өдөр",
            list: "Жагсаалт",
          }}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          events={data}
          eventContent={renderEventContent}
          editable={true}
          selectable={true}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
        />
      </div>
      <Modal
        title={editData ? "Хуваарь мэдээлэл засах" : "Хуваарь нэмэх"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <ScheduleForm
          form={form}
          onFormSubmit={handleSubmit}
          scheduleTypes={scheduleTypes}
        ></ScheduleForm>
      </Modal>

      {contextHolder}
    </div>
  );
}
