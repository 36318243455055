import React, { useEffect, useRef, useState, useContext } from "react";
import { Button, DatePicker, Input, Modal, message, Tag, Select } from "antd";
import {
  SearchOutlined,
  ClearOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import { MainTable } from "components";
import { instance } from "utils/axios";
import moment from "moment";
import { AuthContext } from "contexts";
import { Html5QrcodeScanner } from "html5-qrcode";
import CompoundedSpace from "antd/lib/space";
const { Option } = Select;
function Attendance() {
  const { state, action } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment());

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [scanData, setScanData] = useState(null);
  const scanDataRef = useRef(null);
  // const [processedCodes, setProcessedCodes] = useState(new Set());
  const qrCodeScannerRef = useRef(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [note, setNote] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [grade, setGrade] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [house, setHouse] = useState("");
  const openEditModal = (record) => {
    setSelectedRecord(record);
    setSelectedStatus(record.status);
    setStartDate(moment(record.attendanceDate));
    setEndDate(moment(record.attendanceDate));
    setNote(record.note || ""); // Pre-fill note if it exists
    setIsEditModalVisible(true);
  };

  const handleSave = () => {
    const dataToSave = {
      code: selectedRecord.code,
      status: selectedStatus,
      attendanceDate: selectedRecord.attendanceDate,
      type: "Teacher",
      note: note,
      startDate,
      endDate,
    };

    instance({
      method: "put",
      url: "/student/attendance/update", // Example API endpoint
      data: dataToSave,
    })
      .then(() => {
        message.success("Мэдээлэл амжилттай хадгалагдлаа");
        setIsEditModalVisible(false); // Close modal after saving
        fetchData(); // Refresh the data after saving
      })
      .catch(() => {
        message.error("Хадгалах үед алдаа гарлаа!");
      });
  };

  useEffect(() => {
    action.handleSidebarKey("attendances");
  }, []);
  useEffect(() => {
    fetchData();
  }, [date]);
  useEffect(() => {
    setLoading(true);
    instance({
      method: "get",
      url: `/student/attendance/status`,
      params: {
        date: date ? moment(date).format("YYYY-MM-DD") : undefined,
      },
    })
      .then((res) => {
        setStatusData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [date]);
  const applyFilters = () => {
    let filtered = [...data];
    if (searchText) {
      filtered = filtered.filter(
        (item) =>
          item.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
          item.code.toString().includes(searchText)
      );
    }

    if (grade) {
      filtered = filtered.filter((item) => item.grade === grade);
    }

    if (house) {
      filtered = filtered.filter((item) => item.houseId === house);
    }

    setFilteredData(filtered);
  };
  useEffect(() => {
    applyFilters();
  }, [searchText, grade, house, data]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/student/attendance/list`,
      params: {
        date: date
          ? moment(date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      },
    })
      .then((res) => {
        setData(res.data);
        // setFilteredData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleScanSuccess = (decodedText) => {
    console.log(
      "Decoded text:",
      decodedText,
      "Previous scan data:",
      scanDataRef.current
    );

    if (decodedText !== scanDataRef.current) {
      console.log("New QR code detected:", decodedText);

      scanDataRef.current = decodedText; // This keeps the reference up-to-date

      createAttendance(scanDataRef.current);
    } else {
      console.log("Duplicate or null QR code scanned");
    }
  };
  const handleScanError = (error) => {
    console.debug("QR Code scanning error:", error); // Log error but don't display it
  };

  const createAttendance = (code) => {
    const attendanceData = {
      code: code,
      status: "Ирсэн",
    };

    instance({
      method: "post",
      url: "/student/attendance/create",
      data: attendanceData,
    })
      .then((res) => {
        message.success(`Ирц амжилттай тэмдэглэгдлээ: ${scanDataRef.current}`);
        console.log("Attendance Created:", res.data);
      })
      .catch((err) => {
        message.error("Ирц тэмдэглэх үед алдаа гарлаа!");
        console.error("Error creating attendance:", err);
      });
  };

  const initializeQrScanner = () => {
    if (!qrCodeScannerRef.current) {
      qrCodeScannerRef.current = new Html5QrcodeScanner("reader", {
        fps: 10,
        qrbox: 250,
      });
      qrCodeScannerRef.current.render(handleScanSuccess, handleScanError);
    }
  };

  const openQrScanner = () => {
    setIsModalVisible(true);
    setTimeout(() => initializeQrScanner(), 500); // Delay to ensure the modal is rendered
  };

  const closeQrScanner = () => {
    setIsModalVisible(false);
    if (qrCodeScannerRef.current) {
      qrCodeScannerRef.current.clear(); // Clear the QR scanner on modal close
      qrCodeScannerRef.current = null;
      scanDataRef.current = null;
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      sorter: (a, b) => a.No - b.No,
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "ID",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, row, i) => <span>{row.lastName[0] + ". " + text}</span>,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    },

    {
      title: "Анги",
      dataIndex: "grade",
      key: "grade",
      sorter: (a, b) => a.grade - b.grade,
    },
    {
      title: "House",
      dataIndex: "houseId",
      key: "houseId",
      sorter: (a, b) => a.houseId.localeCompare(b.houseId),
    },
    {
      title: "Огноо",
      dataIndex: "attendanceTime",
      key: "attendanceTime",
      sorter: (a, b) => moment(a.attendanceTime).diff(moment(b.attendanceTime)),
      render: (text) =>
        text ? moment(text).format("YYYY-MM-DD HH:mm:SS") : "",
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      filters: [
        { text: "Ирсэн", value: "Ирсэн" },
        { text: "Хоцорсон", value: "Хоцорсон" },
        { text: "Чөлөөтэй", value: "Чөлөөтэй" },
        { text: "Өвчтэй", value: "Өвчтэй" },
        { text: "Тасалсан", value: "Тасалсан" },
        { text: "Ирээгүй", value: null },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => {
        let color;
        switch (status) {
          case "Ирсэн":
            color = "green";
            break;
          case "Хоцорсон":
            color = "orange";
            break;
          case "Чөлөөтэй":
            color = "blue";
            break;
          case "Тасалсан":
            color = "red";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Үйлдэл",
      key: "action",
      render: (text, record) => (
        <Button
          disabled={record.type === "Qrcode"}
          onClick={() => openEditModal(record)}
        >
          Засах
        </Button>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5">
        <DatePicker value={date} onChange={(e) => setDate(e)} />
        <Input
          placeholder="Name or Code"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          allowClear
        />
        <Select
          placeholder="Сонгох анги"
          value={grade}
          onChange={(value) => setGrade(value)}
          allowClear
        >
          <Option value="">Бүгд</Option>
          <Option value="8">8-р анги</Option>
          <Option value="9">9-р анги</Option>
          <Option value="10">10-р анги</Option>
          <Option value="11">11-р анги</Option>
          <Option value="12">12-р анги</Option>
        </Select>

        <Select
          placeholder="Сонгох хаус"
          value={house}
          onChange={(value) => setHouse(value)}
          allowClear
        >
          <Option value="">Бүгд</Option>
          <Option value="Buhu">Buhu</Option>
          <Option value="Hawt">Hawt</Option>
          <Option value="Irve">Irve</Option>
          <Option value="Khant">Khant</Option>
          <Option value="Maza">Maza</Option>
          <Option value="Tsa">Tsa</Option>
          <Option value="Tsen">Tsen</Option>
          <Option value="Yang">Yang</Option>
        </Select>

        <div className="flex items-center space-x-2">
          <Button
            className="w-[100px]"
            icon={<SearchOutlined />}
            onClick={fetchData}
          ></Button>
          <Button
            type="primary"
            icon={<CameraOutlined />}
            onClick={openQrScanner}
          >
            QR CODE
          </Button>
        </div>
      </div>

      <Modal
        title="QR код уншигч"
        visible={isModalVisible}
        onCancel={closeQrScanner}
        footer={null}
      >
        <div id="reader" style={{ width: "100%" }}></div>

        {scanData && (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <p>
              Сүүлийн уншсан код: <strong>{scanData}</strong>
            </p>
          </div>
        )}
      </Modal>
      <Modal
        title="Статус болон Тэмдэглэл засах"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={handleSave}
      >
        <Select
          value={selectedStatus}
          onChange={setSelectedStatus}
          style={{ width: "100%", marginBottom: 16 }}
        >
          <Select.Option value="Ирсэн">Ирсэн</Select.Option>
          <Select.Option value="Хоцорсон">Хоцорсон</Select.Option>
          <Select.Option value="Чөлөөтэй">Чөлөөтэй</Select.Option>
          <Select.Option value="Өвчтэй">Өвчтэй</Select.Option>
          <Select.Option value="Тасалсан">Тасалсан</Select.Option>
        </Select>
        {(selectedStatus === "Чөлөөтэй" || selectedStatus === "Өвчтэй") && (
          <div style={{ display: "flex", gap: "16px", marginBottom: "16px" }}>
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              style={{ width: "50%" }}
              placeholder="Эхлэх огноо"
            />
            <DatePicker
              value={endDate}
              onChange={(date) => setEndDate(date)}
              style={{ width: "50%" }}
              placeholder="Дуусах огноо"
            />
          </div>
        )}
        <Input.TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Тэмдэглэл оруулах"
        />
      </Modal>
      <div className="grid grid-cols-24 gap-2">
        {statusData?.map((item) => (
          <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
            <div>
              <div className="text-base text-muted">{item.status}</div>
              <div className="font-medium text-xl">
                {Intl.NumberFormat().format(item.cnt)}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={filteredData}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={true}
          title={"Ирцийн жагсаалт"}
        />
      </div>
    </div>
  );
}

export default Attendance;
