import React, { useContext, useEffect, useState } from "react";
import { instance } from "utils/axios";
import { AuthContext } from "contexts";
import PieChart from "./PieChart";
import ColumnChart from "./ColumnChart";

const Dashboard = ({}) => {
  const authContext = useContext(AuthContext);
  const aaa =
    authContext.state.userInfo.profile.firstName == "Admin"?1:-1;
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
   if(aaa!=1) fetchData()
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/dashboard`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setLoading(false));
  };

  return (
    <div className="px-1  md:px-5">
      <div className="grid grid-cols-24 gap-2">
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Төлбөр</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.tuitionAmount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Fin Aid</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.finAid ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-purple-700 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Гэрээт Дүн</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.payAmount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Elf Coin</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.elfCoin ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-orange-400 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Төлсөн</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.paidAmount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-green-700 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Үлдэгдэл</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.balance ?? 0)}
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-start h-full gap-5">
        <PieChart
          data={[
            {
              name: "Үлдэгдэл",
              amount: data.sumData?.balance,
            },
            {
              name: "Төлсөн",
              amount: data.sumData?.paidAmount,
            },
            {
              name: "Fin Aid",
              amount: data.sumData?.finAid,
            },
          ]}
        />
      </div>
      <div className="grid grid-cols-12 gap-5 mt-5">
        <ColumnChart
          data={data?.monthPayData}
          className="col-span-12"
          alias="Төлбөрийн дүн/Сар/"
          xField="tMonth"
          yField="amount"
          start={0}
        />
      </div>
    </div>
  );
};

export default Dashboard;
