import React, { useEffect, useState } from "react";
import { Button, Modal, Tag, Form as AntForm } from "antd";
import { EditOutlined, SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import fields from "./fields";
import { Link } from "react-router-dom";

function Customer() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [form] = AntForm.useForm();
  const [filterForm] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/customer?pageindex=${currentPage}`,
    })
      .then((res) => {
        setData(res.data.customers);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Овог Нэр",
      dataIndex: "lastname",
      key: "lastname",
      render: (text, row) => (
        // <Link className='text-link hover:underline hover:text-link' to={`${row.id}`}>{text} {row.firstname}</Link>
        <span>
          {text} {row.firstname}
        </span>
      ),
    },
    {
      title: "Регистрийн дугаар",
      dataIndex: "regno",
      key: "regno",
    },
    {
      title: "Төрсөн огноо",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Утасны дугаар",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Хүйс",
      dataIndex: "gender",
      key: "gender",
      render: (text, row) => (
        <Tag color={text === 1 ? "blue" : "pink"}>
          {text === 1 ? "Эр" : "Эм"}
        </Tag>
      ),
    },
    {
      title: "Харшилтай эсэх",
      dataIndex: "hasAllergy",
      key: "hasAllergy",
      render: (text) => (
        <Tag color={text === "1" ? "red" : "default"}>
          {text === 1 ? "Тийм" : "Үгүй"}
        </Tag>
      ),
    },
    {
      title: "Харшлын тайлбар",
      dataIndex: "allergyDescr",
      key: "allergyDescr",
    },
    {
      title: "Нэмэлт тайлбар",
      dataIndex: "descr",
      key: "descr",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: "/customer",
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: "/customer",
        data: {
          ...values,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const searchFields = [
    {
      label: "Нэр",
      name: "name",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Утасны дугаар",
      name: "mobile",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];

  const handleFilterSubmit = (values) => {
    setSearchLoading(true);
    instance({
      method: "get",
      url: `/customer?pageindex=0&pagesize=${20}&name=${
        values.name ? values.name : ""
      }&mobile=${values.mobile ? values.mobile : ""}`,
    })
      .then((res) => {
        setData(res.data.customers);
      })
      .catch((err) => {})
      .then(() => setSearchLoading(false));
  };

  const handleAdd = () => {
    setIsEdit(true);
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col px-4 pb-4">
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow mb-5 w-1/2">
        <div className="text-xl leading-none mb-3">Хайлт</div>
        <Form
          form={filterForm}
          fields={searchFields}
          length={data.length}
          layout="vertical"
          onFinish={handleFilterSubmit}
          className="gap-5"
        >
          <div className="col-span-12 flex justify-end gap-2 mt-4">
            <Button
              className="flex items-center"
              size="small"
              type="default"
              htmlType="submit"
              onClick={() => filterForm.submit()}
              loading={searchLoading}
            >
              <SearchOutlined />
              Хайх
            </Button>
            <Button
              className="flex items-center"
              size="small"
              type="default"
              onClick={() => filterForm.resetFields()}
            >
              <ClearOutlined />
              Цэвэрлэх
            </Button>
          </div>
        </Form>
      </div>
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={true}
        title={"Үйлчлүүлэгч"}
        handleAdd={handleAdd}
      />
      <Modal
        title={editData ? "Үйлчлүүлэгчийн мэдээлэл засах" : "Шинэ үйлчлүүлэгч"}
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields()}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Customer;
